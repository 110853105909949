import React from "react";

import "../App.css";

export const Banner = () => {
  return (
    <div className="banner">
      <h2>Harmanjot Singh | Professional Portfolio</h2>
    </div>
  );
};
